import React, {Component} from 'react'
import Layout from 'components/Layout'
// import { Link } from 'gatsby'
import BoohBannerMob from 'images/booh-sub-576.jpg'
import BoohBannerTablet from 'images/booh-sub-991.jpg'
import BoohBannerDesk from 'images/booh-sub-1920.jpg'
import {graphql, StaticQuery} from 'gatsby'
import classnames from 'classnames';
import {
    Container,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink
} from 'reactstrap';
import CustomCollapse from 'components/CustomCollapse'

const headData = {
    title: 'Bat out of Hell! | Cast & Creative',
    description: 'The official site for the worldwide productions of Bat Out of Hell! The Musical based on the songs of Jim Steinman and Meat Loaf',
};

class CastCollapse extends Component {

    constructor(props) {
        super(props)
        this.state = {
            activeTab: '1',
        }
        this.toggle = this.toggle.bind(this);
    }

    toggle(tab, e) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {

        return (
            <Layout
                headData={headData}
                navGlobal={true}
                path="/cast-creative/"
                uk
            >
                <section className="page CastCreativePage bg--black">
                    <div className="CastAndCreativePage--content">
                        <Container fluid={true} className="px-0">

                            <div>
                                <img src={BoohBannerMob} alt="Bat Out Of Hell!" className="img-fluid w-100 d-md-none"/>
                                <img src={BoohBannerTablet} alt="Bat Out Of Hell!"
                                     className="img-fluid w-100 d-none d-md-block d-lg-none"/>
                                <img src={BoohBannerDesk} alt="Bat Out Of Hell!"
                                     className="img-fluid w-100 d-none d-lg-block"/>
                            </div>
                            <Container>
                                <Nav tabs className="c-collapse-nav pt-5 pb-4">
                                    <NavItem>
                                        <NavLink
                                            className={classnames({active: this.state.activeTab === '1'})}
                                            onClick={(e) => {
                                                this.toggle('1', e);
                                            }}
                                        >
                                            CAST
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({active: this.state.activeTab === '2'})}
                                            onClick={(e) => {
                                                this.toggle('2', e);
                                            }}
                                        >
                                            CREATIVE
                                        </NavLink>
                                    </NavItem>
                                    <div
                                        className={`c-collapse-nav nav--scale-bg ${this.state.activeTab === '1' ? 'active' : ''}`}></div>
                                    <div
                                        className={`c-collapse-nav nav--bg ${this.state.activeTab === '2' ? 'active' : ''}`}></div>
                                </Nav>
                                <TabContent activeTab={this.state.activeTab}>
                                    <CastItems
                                        clickHandler={this.clickHandler}
                                        activeId={this.state.activeId}
                                        contentHeight={this.state.contentHeight}
                                    />
                                </TabContent>
                            </Container>
                        </Container>
                    </div>
                </section>
            </Layout>
        )
    }
}

export default CastCollapse

const CastItems = (props) => (
    // Query all sites
    <StaticQuery
        query={graphql`
                query {
                    allCastJson {
                        edges {
                            node {
                                id
                                name
                                role
                                bio
                                image {
                                    childImageSharp {
                                        fluid(maxWidth: 500) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                            }
                        }
                    }
                allCreativeJson {
                    edges {
                        node {
                            id
                            name
                            role
                            bio
                        }
                    }
                }
            }`}
        render={data => (
            <>
                <TabPane tabId="1" key={`cast`}>
                    <CustomCollapse data={data.allCastJson.edges} type="cast"/>
                    {/* <h2 className="mb-0 text-center">Cast to be announced</h2> */}
                </TabPane>
                <TabPane tabId="2" key={`creative`}>
                    <CustomCollapse data={data.allCreativeJson.edges} type="creative" title="Creative"/>
                </TabPane>

            </>
        )}
    />
)
//* Increase in max-width to increase img quality 
//283